// configDetails.js
const configDetails = {
    swFormatted: "+34 677 188 147",
    sw: '+34677188147',
    wappsw: "+34677188147",
    wappswFormatted: "+34677188147",
    email: "ventas@quiagromaquinaria.com",
    spain: "+34 677 188 147",
    infoEmail: 'info@quiagromaquinaria.com',
    emailSpanish:'ventas@quiagromaquinaria.com',
};

export default configDetails;
